import { useEffect, memo } from 'react';
import { useQuery } from '@apollo/client';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from '@reach/router';
import { get } from '../../lib/nodash';
import { identify, group, getAnonymousId, ready } from '../../lib/analytics';
import queryString from 'query-string';

import { backdropApiClient } from '../../gatsby-theme-apollo/client';
import { CURRENT_MEMBER } from '../../queries/industryQueries';
import useApplyDiscountCode from '../Cart/useApplyDiscountCode';
import selectJwt from '../../state/industry/selectJwt';
import selectCartId from '../../state/cart/selectCartId';
import {
  setMember,
  logout,
  setShopifyCustomerId,
  setAnonymousId,
  setJwt,
  setEmail,
  setAuthChecked,
} from '../../state/industry/industrySlice';
import { setClientId } from '../../state/cart/cartSlice';

const MembersApplication = () => {
  const { applyDiscountCode } = useApplyDiscountCode();
  const jwt = useSelector(selectJwt);
  const cartId = useSelector(selectCartId);
  const cartReady = useSelector((state) => state.cart.cartReady);
  const cartChecked = useSelector((state) => state.cart.cartChecked);
  const dispatch = useDispatch();
  const location = useLocation();

  const { data } = useQuery(CURRENT_MEMBER, {
    client: backdropApiClient,
    skip: !jwt,
    onCompleted: (d) => {
      if (!get('currentMember', d)) {
        dispatch(logout());
      } else {
        const customerId = get('currentMember.shopify_customer_id', d);
        dispatch(setShopifyCustomerId(customerId));
      }
    },
    onError: () => {
      dispatch(logout());
    },
  });

  // Apply Member Discount
  useEffect(() => {
    if (
      cartReady &&
      cartChecked &&
      cartId &&
      get('currentMember.referral_code', data)
    ) {
      applyDiscountCode(get('currentMember.referral_code', data));
    }
  }, [cartReady, cartChecked, cartId, data]);

  useEffect(() => {
    const queryParams = queryString.parse(location.search);
    if (queryParams.backdropApiToken) {
      dispatch(setJwt(queryParams.backdropApiToken));
    }
    if (queryParams.memberEmail) {
      dispatch(setEmail(queryParams.memberEmail));
    }
    dispatch(setAuthChecked(true));
  }, [location]);

  useEffect(() => {
    if (get('currentMember', data)) {
      dispatch(setMember(get('currentMember', data)));
      if (get('currentMember.shopify_customer_id', data)) {
        identify(
          get('currentMember.shopify_customer_id', data),
          get('currentMember', data)
        );
        if (get('currentMember.member_group', data)) {
          group(get('currentMember.member_group', data));
        }
      }
    }
  }, [data]);

  useEffect(() => {
    const func = () => {
      if (window.ga) {
        const gaClientId = window.ga.getAll().map((x) => x.get('clientId'))[0];
        dispatch(setAnonymousId(getAnonymousId()));
        if (gaClientId) {
          dispatch(setClientId(gaClientId));
        }
      }
    };
    ready(null, null, null, null, func);
  }, [dispatch, setAnonymousId, setClientId]);

  return null;
};

export default memo(MembersApplication);
