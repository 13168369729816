import React, {
  useMemo,
  useState,
  memo,
  useCallback,
  useEffect,
  forwardRef,
} from 'react';
import PropTypes from 'prop-types';
import { filter, get, trim } from '../../lib/nodash';
import { useSelector } from 'react-redux';
import { Box, Button, Stack } from 'grommet';
import useInterval from '@use-it/interval';
import { LazyMotion, AnimatePresence, m } from 'framer-motion';
import { Close } from 'grommet-icons';

import Announcement from './Announcement';
import useMember from '../Industry/useMember';
import isVisibleByVisibilitySettings from '../../lib/isVisibleByVisibilitySettings';
import selectDiscountCode from '../../state/cart/selectDiscountCode';
import selectJwt from '../../state/industry/selectJwt';
import useHover from '../useHover';
import useMobile from '../useMobile';
import { useWindowSize } from '../useWindowSize';

const ForwardedBox = forwardRef((props, ref) => <Box ref={ref} {...props} />);
ForwardedBox.displayName = 'ForwardedBox';
const MotionBox = m.create(ForwardedBox);

const loadFeatures = () =>
  import('../../lib/framer/motionFeatures.js').then((res) => res.default);

const AnnouncementBar = ({
  announcements,
  isOpen = true,
  onClose,
  style,
  onAnimationComplete,
  interval = 5000,
}) => {
  const member = useMember();
  const discountCode = useSelector(selectDiscountCode);
  const jwt = useSelector(selectJwt);
  const [hoverRef, isHovered] = useHover();
  const isMobile = useMobile();
  const visibleAnnouncements = useMemo(
    () =>
      filter(
        (x) =>
          isVisibleByVisibilitySettings(get('visibility', x), {
            member,
            jwt,
            discountCode,
          }),
        announcements
      ),
    [member, jwt, discountCode, announcements]
  );
  const [active, setActive] = useState(0);
  const [zipCode, setZipCode] = useState(null);
  const geofencedAnnouncements = useMemo(
    () =>
      visibleAnnouncements.filter(
        (x) => x.zipCodes && x.zipCodes.split(',').length
      ),
    [visibleAnnouncements]
  );

  useEffect(() => {
    const fetchLocation = async () => {
      const result = await fetch(process.env.GATSBY_IP_API_ENDPOINT);
      const { zipcode } = await result.json();
      setZipCode(zipcode);
    };
    if (geofencedAnnouncements.length && !zipCode) {
      fetchLocation();
    }
  }, [geofencedAnnouncements]);

  const handleTransition = useCallback(
    (nextIndex) => {
      if (!isHovered) {
        setActive(nextIndex);
      }
    },
    [setActive, isHovered]
  );

  const availableAnnouncements = useMemo(
    () =>
      visibleAnnouncements.filter((x) => {
        const cleanedZip = (zipCode || '').split('-')[0];

        return x.zipCodes
          ? x.zipCodes
              .split(',')
              .map((z) => trim(z))
              .indexOf(cleanedZip) > -1
          : true;
      }),
    [zipCode, visibleAnnouncements]
  );

  const [width] = useWindowSize();
  useEffect(() => {
    if (hoverRef.current) {
      onAnimationComplete(
        null,
        hoverRef.current.getBoundingClientRect().height
      );
    }
  }, [width]);

  useInterval(() => {
    const nextIndex =
      active + 2 > availableAnnouncements.length ? 0 : active + 1;
    handleTransition(nextIndex);
  }, interval);
  const activeAnnouncement = availableAnnouncements[active];

  return (
    <LazyMotion strict features={loadFeatures}>
      <m.div
        animate={{ height: isOpen ? '37px' : 0 }}
        style={{ overflow: 'hidden', ...style }}
        layout="size"
        ref={hoverRef}
        onAnimationComplete={(def) =>
          onAnimationComplete(
            def,
            hoverRef.current.getBoundingClientRect().height
          )
        }
      >
        <Stack anchor="right">
          <MotionBox
            background="brand"
            align="center"
            pad="small"
            layout="size"
            overflow="hidden"
            height="37px"
          >
            <AnimatePresence exitBeforeEnter>
              {activeAnnouncement && (
                <m.div
                  key={activeAnnouncement._uid}
                  initial={{ y: 30, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  exit={{ y: -30, opacity: 0 }}
                  transition={{ duration: 0.7 }}
                >
                  <Announcement
                    content={
                      isMobile
                        ? activeAnnouncement.mobileContent ||
                          activeAnnouncement.content
                        : activeAnnouncement.content
                    }
                  />
                </m.div>
              )}
            </AnimatePresence>
          </MotionBox>
          <Box pad={{ vertical: 'xsmall', horizontal: 'small' }}>
            <Button
              icon={<Close color="white" size={isMobile ? '16px' : 'small'} />}
              plain
              onClick={onClose}
            />
          </Box>
        </Stack>
      </m.div>
    </LazyMotion>
  );
};

AnnouncementBar.propTypes = {
  announcements: PropTypes.array,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  style: PropTypes.object,
  onAnimationComplete: PropTypes.func,
  interval: PropTypes.number,
};

export default memo(AnnouncementBar);
