import React, {
  useContext,
  useEffect,
  useRef,
  useCallback,
  useMemo,
  memo,
  useLayoutEffect,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { navigate } from 'gatsby';
import { useMutation, useQuery } from '@apollo/client';
import { Box, Heading, Button, ResponsiveContext, Keyboard } from 'grommet';
import { map, reject, get, uniqBy } from '../../lib/nodash';
import { Close } from 'grommet-icons';
import deNodify from '../../lib/deNodify';
import loadable from '@loadable/component';
import { use100vh } from 'react-div-100vh';
import { m, LazyMotion, AnimatePresence } from 'framer-motion';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

import useCart from './useCart';
import useGoogleAnalyticsProperties from '../useGoogleAnalyticsProperties';
import {
  setCartId,
  setFailedDiscountCode,
  setCartReady,
  resetSamplesArrangement,
} from '../../state/cart/cartSlice';
import selectDiscountCode from '../../state/cart/selectDiscountCode';
import {
  CREATE_CART,
  UPDATE_CART_ATTRIBUTES,
  GET_CART,
} from '../../queries/cartQueries';
import { SHOP_METAFIELDS } from '../../queries/shopQueries';
import { shopifyClient } from '../../gatsby-theme-apollo/client';
import { isSample, isMemo, isPrimer } from '../../lib/product';

import {
  PRODUCT_ADDED_FROM_CHECKOUT,
  CART_VIEWED,
} from '../../lib/analytics/segmentActions';
import { track } from '../../lib/analytics';
import useApplyDiscountCode from './useApplyDiscountCode';

const loadFeatures = () =>
  import('../../lib/framer/motionFeatures.js').then((res) => res.default);

const CartItems = loadable(() => import('./CartItems'));
const CartSectionHeader = loadable(() => import('./CartSectionHeader'));
const CartShopCollectionButtons = loadable(() =>
  import('./CartShopCollectionButtons')
);
const CartTotals = loadable(() => import('./CartTotals'));
const CartDiscountHeader = loadable(() => import('./CartDiscountHeader'));
const CartSuppliesDiscountHeader = loadable(() =>
  import('./CartSuppliesDiscountHeader')
);
const CartRecommendedProducts = loadable(() =>
  import('./CartRecommendedProducts')
);
const Loading = loadable(() => import('../Loading'));
const MotionBox = m.create(Box);

const variants = {
  active: {
    x: 0,
    transition: {
      type: 'tween',
      ease: 'circOut',
      duration: 0.25,
    },
  },
  inactive: {
    x: '100%',
    transition: {
      type: 'tween',
      ease: 'easeOut',
      duration: 0.25,
    },
  },
};

const Cart = () => {
  const dispatch = useDispatch();
  const {
    setOpen,
    lineItems,
    loading,
    samples,
    removeFromCart,
    paintItems,
    adjustLineItemQuantity,
    customAttributes,
    subtotal,
  } = useCart();
  const size = useContext(ResponsiveContext);
  const { applyDiscountCode } = useApplyDiscountCode();
  const { sessionId, sessionNumber } = useGoogleAnalyticsProperties();
  const scrollContainer = useRef(null);
  const cartActive = useSelector((state) => state.cart.open);
  const cartId = useSelector((state) => state.cart.cartId);
  const cartReady = useSelector((state) => state.cart.cartReady);
  const cartChecked = useSelector((state) => state.cart.cartChecked);
  const failedDiscountCode = useSelector(
    (state) => state.cart.failedDiscountCode
  );
  const discountCode = useSelector(selectDiscountCode);
  const anonymousId = useSelector((state) => state.industry.anonymousId);
  const samplesArrangement = useSelector(
    (state) => state.cart.samplesArrangement
  );
  const clientId = useSelector((state) => state.cart.clientId);
  const allItems = deNodify(lineItems);
  const viewportHeight = use100vh();

  const onClose = useCallback(() => {
    setOpen(false);
  });

  const isMobile = size === 'small';
  const isSmall = isMobile || size === 'medsmall';

  const nonSampleLines = useMemo(
    () => reject(isSample, map('node', lineItems)),
    [lineItems]
  );

  const nonWallcoveringSamples = samples.filter(
    (x) => x.product.productType !== 'Wallcoverings'
  );

  const samplesOnly = lineItems
    .map((x) => x.node.merchandise)
    .every((x) => isSample(x) || isMemo(x));
  const nonPrimerPaints = reject(isPrimer, paintItems);

  // Mutations
  const [createCart] = useMutation(CREATE_CART, {
    variables: { input: {} },
    client: shopifyClient,
    onCompleted: (d) => {
      dispatch(setCartId(get('cartCreate.cart.id', d)));
    },
  });
  const [updateAttributes] = useMutation(UPDATE_CART_ATTRIBUTES, {
    client: shopifyClient,
  });

  // Queries
  const { data: metafieldsData } = useQuery(SHOP_METAFIELDS, {
    client: shopifyClient,
  });

  useQuery(GET_CART, {
    client: shopifyClient,
    variables: {
      id: cartId,
    },
    skip: !cartId || cartReady,
    onCompleted: (d) => {
      if (!d.cart) {
        dispatch(setCartReady(false));
        dispatch(resetSamplesArrangement());
      } else {
        dispatch(setCartReady(true));
      }
    },
  });

  const shopMetafields = get('shop', metafieldsData);

  // Effects
  useEffect(() => {
    if (cartChecked && !cartReady) {
      dispatch(resetSamplesArrangement());
      createCart();
    }
    if (!cartId && !cartChecked && !cartReady) {
      dispatch(resetSamplesArrangement());
      createCart();
    }
  }, [cartId, cartChecked, cartReady]);

  // Retry discount codes after checkout is ready
  useEffect(() => {
    if (cartChecked && cartReady && failedDiscountCode) {
      if (failedDiscountCode === discountCode) {
        dispatch(setFailedDiscountCode(null));
      } else {
        applyDiscountCode(failedDiscountCode);
      }
    }
  }, [cartId, cartChecked, cartReady, failedDiscountCode]);

  useEffect(() => {
    if (anonymousId && cartId && cartReady && cartChecked) {
      updateAttributes({
        variables: {
          cartId: cartId,
          attributes: uniqBy(
            (x) => x.key,
            [
              ...(customAttributes || []),
              { key: 'anonymous_id', value: anonymousId },
              ...(clientId ? [{ key: 'client_id', value: clientId }] : []),
              ...(sessionId
                ? [{ key: 'ga_session_id', value: sessionId }]
                : []),
              ...(sessionNumber
                ? [
                    {
                      key: 'ga_session_number',
                      value: sessionNumber.toString(),
                    },
                  ]
                : []),
            ]
          ).map((attr) => ({
            key: attr.key,
            value: attr.value,
          })),
        },
      });
    }
  }, [
    anonymousId,
    updateAttributes,
    cartId,
    clientId,
    sessionId,
    sessionNumber,
  ]);

  useEffect(() => {
    if (cartActive) {
      track(CART_VIEWED, {
        cart_id: cartId,
        value: subtotal,
        currency: 'USD',
        products: lineItems.map((p, i) => ({
          product_id: get('node.merchandise.product.id', p),
          sku: get('node.merchandise.id', p),
          variant: get('node.merchandise.title', p),
          name: get('node.merchandise.product.title', p),
          price: parseFloat(get('node.merchandise.price.amount', p)),
          position: i,
          category: get('node.merchandise.product.productType', p),
          url: get('node.merchandise.product.onlineStoreUrl', p),
          image_url: get('node.merchandise.image.thumb', p),
        })),
      });
    }
  }, [cartActive]);

  useLayoutEffect(() => {
    if (cartActive && scrollContainer.current) {
      disableBodyScroll(scrollContainer.current);
    } else {
      clearAllBodyScrollLocks();
    }
  });

  return (
    <LazyMotion strict features={loadFeatures}>
      <Keyboard
        target="document"
        onEsc={() => {
          cartActive ? dispatch(setOpen(false)) : undefined;
        }}
      >
        <MotionBox
          style={{ zIndex: 1005, position: 'fixed', top: 0, right: 0 }}
          background="white"
          height={`${viewportHeight}px`}
          variants={variants}
          animate={cartActive ? 'active' : 'inactive'}
          initial="inactive"
          width={isMobile ? '100vw' : 'auto'}
        >
          <Box
            width={isSmall ? '100%' : '690px'}
            fill="vertical"
            border={
              isMobile
                ? undefined
                : { side: 'left', size: 'small', color: 'black' }
            }
          >
            <Box className="cart" fill>
              <Box flex={true} className="cart-body">
                <Box
                  className="cart-body--scroll-container"
                  flex={false}
                  overflow="auto"
                  height="100%"
                  ref={scrollContainer}
                >
                  {loading ? (
                    <Box fill align="center" justify="center" pad="large">
                      <Loading size="large" />
                    </Box>
                  ) : (
                    <Box
                      className="cart-body--scroll-content"
                      flex={lineItems.length === 0 ? { grow: 1 } : false}
                    >
                      <CartDiscountHeader onClose={onClose} />
                      <AnimatePresence>
                        {cartActive && (
                          <MotionBox
                            className="cart-items"
                            flex={{ grow: 1 }}
                            fill={lineItems.length === 0}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                          >
                            {allItems.length > 0 && (
                              <CartItems
                                items={allItems}
                                onRemove={removeFromCart}
                                loading={loading}
                                onAdjustQuantity={adjustLineItemQuantity}
                                shopMetafields={shopMetafields}
                                samplesOnly={samplesOnly}
                                samplesArrangement={samplesArrangement}
                              />
                            )}
                            {lineItems.length === 0 && (
                              <Box
                                gap="medium"
                                pad="large"
                                align="center"
                                justify="center"
                                fill
                              >
                                <Heading level={4}>Your Cart is empty</Heading>
                                <Button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    onClose();
                                    navigate(
                                      '/collections/paint/interior/standard'
                                    );
                                  }}
                                  primary
                                  href="/collections/paint/interior/standard"
                                  label="Browse Samples"
                                />
                              </Box>
                            )}
                          </MotionBox>
                        )}
                      </AnimatePresence>

                      {(nonPrimerPaints.length > 0 ||
                        nonWallcoveringSamples.length > 0) &&
                        !samplesOnly && (
                          <Box background="#f0f0f0">
                            <CartSectionHeader title="Recommended Supplies" />
                            <CartSuppliesDiscountHeader />

                            <Box
                              margin={
                                isMobile ? { vertical: 'small' } : 'small'
                              }
                            >
                              {
                                <CartRecommendedProducts
                                  cartItems={nonSampleLines}
                                  onAddToCart={({ variantId, quantity }) =>
                                    track(PRODUCT_ADDED_FROM_CHECKOUT, {
                                      variantId,
                                      quantity,
                                    })
                                  }
                                />
                              }
                            </Box>

                            {!isMobile && (
                              <CartShopCollectionButtons
                                margin="small"
                                pad={{
                                  horizontal: 'medium',
                                  top: 'small',
                                  bottom: 'medium',
                                }}
                                gap="small"
                                direction="row"
                              />
                            )}
                          </Box>
                        )}
                    </Box>
                  )}
                </Box>
              </Box>
              <CartTotals
                items={lineItems}
                border={{ side: 'top', size: 'small', color: 'black' }}
                cartId={cartId}
              />

              <Box
                style={{ position: 'absolute', top: 0, right: 0, zIndex: 1001 }}
                onClick={onClose}
                pad="18px"
              >
                <Close color="white" size="28px" />
              </Box>
            </Box>
          </Box>
        </MotionBox>
      </Keyboard>
    </LazyMotion>
  );
};

export default memo(Cart);
