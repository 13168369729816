import jsCookie from 'js-cookie';
import { DOMAIN } from '../lib/constants';
import { configureStore } from '@reduxjs/toolkit';
import galleryReducer from './gallery/gallerySlice';
import cartReducer from './cart/cartSlice';
import industryReducer from './industry/industrySlice';
import calculatorReducer from './calculator/calculatorSlice';
import productReducer from './product/productSlice';
import uiReducer from './ui/uiSlice';
import wallcoveringsReducer from './wallcoverings/wallcoveringsSlice';
import storyblokReducer from './storyblok/storyblokSlice';
import colorReducer from './color/colorSlice';
import collectionReducer from './collection/collectionSlice';
import reduxCookiesMiddleware, {
  getStateFromCookies,
} from 'redux-cookies-middleware';

// state to persist in cookies
const paths = {
  'cart.cartId': { name: 'backdrop-cart-id' },
  'cart.discountCode': { name: 'discount_code' },
  'cart.discountCodeAmount': { name: 'discount_code_amount' },
  'cart.campaignDiscounts': { name: 'campaign_discounts' },
  'cart.samplesArrangement': { name: 'samples_arrangement' },
  'wallcoverings.filterPanelActive': {
    name: 'wallcoverings_filter_panel_active',
  },
  'industry.jwt': { name: 'backdrop-api-token' },
  'industry.referralCode': { name: 'discount_code' },
  'industry.shopifyCustomerId': { name: 'customer_id' },
  'calculator.calcVariables.people': { name: 'backdrop-people-count' },
  'calculator.customerName': { name: 'cfn' },
};

const store = (
  preloadedState = {
    wallcoverings: {
      filterPanelActive: false,
      activeFilterCategories: [0],
    },
  }
) => {
  const isBrowser = typeof window !== 'undefined';
  const state = isBrowser
    ? getStateFromCookies(preloadedState, paths)
    : preloadedState;

  return configureStore({
    reducer: {
      gallery: galleryReducer,
      cart: cartReducer,
      industry: industryReducer,
      calculator: calculatorReducer,
      product: productReducer,
      ui: uiReducer,
      wallcoverings: wallcoveringsReducer,
      color: colorReducer,
      collection: collectionReducer,
      storyblok: storyblokReducer,
    },
    preloadedState: state,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(
        reduxCookiesMiddleware(paths, {
          setCookie: (name, value, expiry = 365, secure = false) => {
            jsCookie.set(name, value, {
              expires: expiry,
              path: '/',
              secure,
              domain: DOMAIN,
            });
          },
          getCookie: (name) => jsCookie.get(name),
        })
      ),
  });
};

export default store;
