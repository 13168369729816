import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Box, Heading, Button } from 'grommet';
import { FormNext } from 'grommet-icons';
import { LazyMotion, m } from 'framer-motion';

import SmartLink from './SmartLink';
import useHover from './useHover';
import {
  setActiveHeaderSubMenu,
  toggleActiveSubmenu,
} from '../state/ui/uiSlice';
import useVisibilitySettings from './useVisibilitySettings';
import selectMember from '../state/industry/selectMember';
import formatCurrency from '../lib/formatCurrency';
import useMobile from './useMobile';
import getStoryblokLinkUrl from '../lib/getStoryblokLinkUrl';

const loadFeatures = () =>
  import('../lib/framer/motionFeatures.js').then((res) => res.default);

const MotionNextIcon = m.create(FormNext);

const HeaderMenu = (props) => {
  const {
    title,
    text,
    dark,
    fixed,
    _uid,
    visibility,
    headingLevel = 5,
    style,
    border,
    link,
    product,
    ...rest
  } = props;
  const dispatch = useDispatch();
  const isMobile = useMobile();
  const [ref] = useHover(() => {
    if (!isMobile) {
      dispatch(setActiveHeaderSubMenu(_uid));
    }
  });
  const isVisible = useVisibilitySettings(visibility);
  const member = useSelector(selectMember);
  const isActiveMenu = useSelector((state) => state.ui.activeSubmenus[_uid]);
  const isActive = useSelector(
    (state) => state.ui.activeHeaderSubMenu === _uid
  );

  const memberCredit = useSelector((state) => state.industry.memberCredit);
  const activeHeaderSubMenu = useSelector(
    (state) => state.ui.activeHeaderSubMenu
  );

  const url = getStoryblokLinkUrl(link, product);

  const templated = useMemo(
    () =>
      (title || text || '')
        .replace(
          /{{ firstName }}/gm,
          member ? member.firstName || member.first_name : ''
        )
        .replace(/{{ referralCode }}/gm, member ? member.referral_code : '')
        .replace(
          /{{ credit }}/gm,
          memberCredit ? formatCurrency(memberCredit) : ''
        ),
    [member, memberCredit, title, text]
  );

  return isVisible ? (
    <LazyMotion strict features={loadFeatures}>
      <Box
        ref={ref}
        style={{ position: 'relative' }}
        overflow={'hidden'}
        background="white"
      >
        <SmartLink
          as={Button}
          compAs="a"
          className="menu-button"
          to={url}
          onClick={
            isMobile
              ? rest.items && rest.items.length > 0
                ? (e) => {
                    e.preventDefault();
                    dispatch(toggleActiveSubmenu(_uid));
                  }
                : undefined
              : url === '#'
              ? (e) => {
                  e.preventDefault();
                }
              : undefined
          }
          icon={
            isMobile ? (
              <MotionNextIcon
                color="brand"
                animate={{ rotate: isActiveMenu ? '-180deg' : '0deg' }}
              />
            ) : undefined
          }
          justify="between"
          color="transparent"
          reverse
          label={
            <Heading
              margin="0"
              as="p"
              level={headingLevel}
              color={
                isMobile
                  ? 'black'
                  : isActive
                  ? 'white'
                  : dark && !fixed && !activeHeaderSubMenu
                  ? 'white'
                  : 'black'
              }
            >
              {templated}
            </Heading>
          }
          {...rest}
          style={{
            ...style,
            backgroundColor: isActive ? '#000' : 'transparent',
          }}
          border={border}
        />
      </Box>
    </LazyMotion>
  ) : null;
};

HeaderMenu.propTypes = {
  image: PropTypes.object,
  items: PropTypes.array,
  title: PropTypes.string,
  text: PropTypes.string,
  component: PropTypes.string,
  dark: PropTypes.bool,
  fixed: PropTypes.bool,
  _uid: PropTypes.string,
  visibility: PropTypes.array,
  headingLevel: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
  style: PropTypes.object,
  plain: PropTypes.bool,
  border: PropTypes.object,
  link: PropTypes.object,
  product: PropTypes.object,
};

export default memo(HeaderMenu);
