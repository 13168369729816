import { gql } from '@apollo/client';

// *Updated
export const CART_LINE_ITEMS_FRAGMENT = gql`
  fragment CartLineItems on Cart {
    lines(first: 100) {
      edges {
        node {
          id
          __typename
          quantity
          cost {
            totalAmount {
              amount
            }
            subtotalAmount {
              amount
            }
            amountPerQuantity {
              amount
            }
          }
          discountAllocations {
            discountApplication {
              allocationMethod
              targetSelection
              targetType
            }

            discountedAmount {
              amount
            }
          }
          merchandise {
            __typename
            ... on ProductVariant {
              id
              availableForSale
              title
              currentlyNotInStock
              price {
                amount
              }
              image {
                thumb: url(transform: { maxWidth: 150, maxHeight: 150 })
                original: url
              }
              product {
                productType
                id
                title
                tags
                handle
                out_of_stock_message: metafield(
                  namespace: "accentuate"
                  key: "out_of_stock_message"
                ) {
                  id
                  key
                  value
                  namespace
                }
              }
            }
          }
          discountAllocations {
            discountedAmount {
              amount
            }
          }
          attributes {
            key
            value
          }
        }
      }
    }
  }
`;

// *Updated
export const CREATE_CART = gql`
  mutation cartCreate($input: CartInput!) {
    cartCreate(input: $input) {
      cart {
        id
        __typename
      }
      userErrors {
        code
        message
        field
      }
    }
  }
`;

// *Updated
export const GET_CART = gql`
  ${CART_LINE_ITEMS_FRAGMENT}
  query Cart($id: ID!) {
    cart(id: $id) {
      id
      __typename
      createdAt
      updatedAt
      checkoutUrl
      cost {
        subtotalAmount {
          amount
        }
        totalAmount {
          amount
        }
        checkoutChargeAmount {
          amount
        }
      }
      discountCodes {
        applicable
        code
      }
      discountAllocations {
        discountedAmount {
          amount
        }
      }
      attributes {
        key
        value
      }
      ...CartLineItems
    }
  }
`;

// *Updated

export const CART_LINE_ITEMS_UPDATE = gql`
  ${CART_LINE_ITEMS_FRAGMENT}
  mutation cartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
    cartLinesUpdate(cartId: $cartId, lines: $lines) {
      cart {
        id
        __typename
        cost {
          subtotalAmount {
            amount
          }
          totalAmount {
            amount
          }
        }
        ...CartLineItems
      }
      userErrors {
        field
        message
      }
    }
  }
`;

// *Updated
export const CART_LINE_ITEMS_REMOVE = gql`
  ${CART_LINE_ITEMS_FRAGMENT}
  mutation cartLinesRemove($cartId: ID!, $lineIds: [ID!]!) {
    cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
      cart {
        id
        __typename
        cost {
          subtotalAmount {
            amount
          }
          totalAmount {
            amount
          }
        }
        ...CartLineItems
      }
      userErrors {
        field
        message
      }
    }
  }
`;

// *Updated
export const CART_LINE_ITEMS_ADD = gql`
  ${CART_LINE_ITEMS_FRAGMENT}
  mutation cartLineItemsAdd($lines: [CartLineInput!]!, $cartId: ID!) {
    cartLinesAdd(lines: $lines, cartId: $cartId) {
      cart {
        __typename
        id
        checkoutUrl
        cost {
          subtotalAmount {
            amount
          }
          totalAmount {
            amount
          }
        }
        ...CartLineItems
      }
      userErrors {
        code
        field
        message
      }
    }
  }
`;

// *Updated
export const APPLY_DISCOUNT_CODE = gql`
  mutation cartDiscountCodesUpdate($cartId: ID!, $discountCodes: [String!]) {
    cartDiscountCodesUpdate(cartId: $cartId, discountCodes: $discountCodes) {
      cart {
        id
        __typename
        cost {
          subtotalAmount {
            amount
          }
          totalAmount {
            amount
          }
        }
        discountCodes {
          applicable
          code
        }
        discountAllocations {
          discountedAmount {
            amount
          }
        }
      }
      userErrors {
        code
        field
        message
      }
    }
  }
`;

// *Updated
export const UPDATE_CART_ATTRIBUTES = gql`
  mutation cartAttributesUpdate($cartId: ID!, $attributes: [AttributeInput!]!) {
    cartAttributesUpdate(cartId: $cartId, attributes: $attributes) {
      cart {
        id
        __typename
        attributes {
          key
          value
        }
      }
      userErrors {
        code
        field
        message
      }
    }
  }
`;
