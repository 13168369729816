import { createSelector } from 'reselect';

const selectCartId = createSelector(
  (state) => state.cart,
  (cart) => {
    return cart.cartId;
  }
);

export default selectCartId;
