// Methods for replicating the quantity discounts in Shopify Flow before a Checkout
// is created. If possible, don't use in cart/checkout and defer to the values
// that Shopify responds with.

import { PAINT_PRODUCT_TYPES, SUPPLY_PRODUCT_TYPES } from '../shopConstants';

import {
  get,
  includes,
  filter,
  reduce,
  isEqual,
  lowerCase,
  add,
  subtract,
  multiply,
  reject,
  divide,
} from '../nodash';
import {
  getProdType,
  getVariantGallonCount,
  getVariantTitle,
  isVariantHalfGal,
} from '../product';
import calculateSavedOnSupplies from './calculateSavedOnSupplies';
import deNodify from '../deNodify';

const is5Gal = (x) => isEqual(getProdType(x), 'Exterior Standard');
export const PAINT_SUPPLY_DISCOUNT_THRESHOLD = 300;
export const PAINT_AND_SUPPLY_DISCOUNT_PERCENTAGE = 0.1;

const getQuantity = (x) => get('quantity', x) || get('node.quantity', x);
const sumQuantity = (mem, x) => {
  const qty = getQuantity(x);
  const amount = isVariantHalfGal(x) ? qty * 0 : qty * getVariantGallonCount(x);
  return mem + amount;
};

export const isPaint = (x) =>
  includes(getProdType(x), PAINT_PRODUCT_TYPES) &&
  !includes('sample', lowerCase(getVariantTitle(x)));
export const isSample = (x) =>
  includes(getProdType(x), PAINT_PRODUCT_TYPES) &&
  includes('sample', lowerCase(getVariantTitle(x)));
export const isPrimer = (x) =>
  includes(getProdType(x), PAINT_PRODUCT_TYPES) &&
  includes('primer', lowerCase(getVariantTitle(x)));
export const isSupplies = (x) => includes(getProdType(x), SUPPLY_PRODUCT_TYPES);
const getPaintItems = (items) => filter(isPaint, items);
const getSampleItems = (items) => filter(isSample, items);
const getSupplyItems = (items) => filter(isSupplies, items);
const withoutPrimer = (items) => reject(isPrimer, items);

const getGallonCount = (items) => {
  return reduce(sumQuantity, 0, getPaintItems(items));
};

const getActiveDiscounts = (items, isIndustry) => {
  if (isIndustry) {
    return ['paint-and-supply-discount', 'twenty-percent-off-paint'];
  }

  const paintSupplyDiscountActive = false;

  let activeDiscounts = [];
  // $300+ on paint/supplies = 10% off those items
  if (paintSupplyDiscountActive) {
    activeDiscounts = activeDiscounts.concat('paint-and-supply-discount');
  }

  return activeDiscounts;
};

export const getSuppliesTotal = (items) =>
  reduce(
    (mem, x) => {
      return (
        parseFloat((get('price.amount', x) ?? get('price', x)) + mem) *
        getQuantity(x)
      );
    },
    0,
    getSupplyItems(items)
  );

export const getPaintTotal = (items) =>
  reduce(
    (mem, x) => {
      const price =
        get('price.amount', x) ??
        get('price', x) ??
        get('priceV2.amount', x) ??
        get('variant.priceV2.amount', x) ??
        get('variant.price.amount', x) ??
        0;
      return parseFloat(price) * getQuantity(x) + mem;
    },
    0,
    deNodify(getPaintItems(items))
  );

const gallonsToSupplyDiscount = (items) => {
  const diff = 2 - getGallonCount(items);
  return diff < 0 ? 0 : diff;
};

const gallonsToPaintDiscount = (items) => {
  const diff = 4 - getGallonCount(items);
  return diff < 0 ? 0 : diff;
};

const getDiscountedTotal = (items) => {
  // Legacy discount code was discounting if order was over $300.
  // This has been removed, so it's just a pass-through reducer now.
  const hasPaintAndSupplyDiscount = false;

  const discountedTotal = reduce(
    (mem, x) => {
      const pricePer = parseFloat(get('price.amount', x) ?? get('price', x));
      const discountedPricePer = hasPaintAndSupplyDiscount
        ? pricePer * (1 - PAINT_AND_SUPPLY_DISCOUNT_PERCENTAGE)
        : pricePer;

      mem = add(multiply(discountedPricePer, get('quantity', x)), mem);
      return mem;
    },
    0,
    items
  );

  return multiply(discountedTotal, 100);
};

const getSuppliesTotalDiscount = (items) => {
  return calculateSavedOnSupplies(items);
};

const getDiscountedItemPrice = (price, discount, itemsPerUnit = 1) => {
  // returns cents
  switch (get('type', discount)) {
    case 'dollars':
      return divide(
        subtract(
          multiply(price, 100),
          multiply(itemsPerUnit, multiply(100, get('value', discount)))
        ),
        100
      );
    case 'percent':
      return divide(
        multiply(
          divide(subtract(100, get('value', discount)), 100),
          multiply(price, 100)
        ),
        100
      );
    default:
      return subtract(get('value', discount), price);
  }
};

const getTotalDiscountedPrice = (items, subtotal, activeDiscounts = []) => {
  const suppliesTotalDiscount = getSuppliesTotalDiscount(items);
  const suppliesTotal = getSuppliesTotal(items);
  return divide(
    add(
      subtract(suppliesTotalDiscount, suppliesTotal),
      getDiscountedTotal(items, activeDiscounts)
    ),
    100
  );
};

export default {
  getGallonCount,
  getActiveDiscounts,
  gallonsToSupplyDiscount,
  gallonsToPaintDiscount,
  getDiscountedTotal,
  getSuppliesTotalDiscount,
  getTotalDiscountedPrice,
  getSampleItems,
  getDiscountedItemPrice,
  getPaintItems,
  withoutPrimer,
  isPrimer,
  is5Gal,
  isPaint,
  isSupplies,
};
