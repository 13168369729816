import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from 'grommet';
import { m } from 'framer-motion';

import HeaderMenuComponent from './HeaderMenuComponent';

const MotionBox = m.create(Box);

const HeaderSubMenu = ({ items = [], isActive, ...rest }) => {
  const baseColCount = 3;

  return (
    <MotionBox
      initial={{ opacity: 0, pointerEvents: 'none' }}
      animate={
        isActive
          ? {
              opacity: 1,
              pointerEvents: 'all',
              transition: { type: 'tween', duration: 0.1 },
            }
          : {
              opacity: 0,
              pointerEvents: 'none',
              transition: { type: 'tween', duration: 0.1 },
            }
      }
      style={{ position: 'absolute', zIndex: 1 }}
      fill="horizontal"
      border={{ side: 'horizontal', size: 'small' }}
      background="white"
    >
      <Grid gap="medium" columns={{ count: baseColCount, size: 'auto' }}>
        {items.map((x, i) => {
          const component =
            x.component === 'MenuSection'
              ? 'MenuSection'
              : x.component === 'MenuItem'
              ? 'SubMenuItem'
              : 'SubMenu';
          return (
            <HeaderMenuComponent
              key={x._uid}
              {...x}
              component={component}
              last={items.length - 1 === i}
              parent={rest}
              sub={true}
              index={i}
            />
          );
        })}
      </Grid>
    </MotionBox>
  );
};

HeaderSubMenu.propTypes = {
  title: PropTypes.string,
  items: PropTypes.array,
  _uid: PropTypes.string,
  isActive: PropTypes.bool,
};

export default memo(HeaderSubMenu);
