import { memo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { get, head, find } from '../../lib/nodash';
import { useQuery } from '@apollo/client';
import queryString from 'query-string';

import { CAMPAIGN_BY_QUERY } from '../../queries/storyblokQueries';
import { storyblokClient } from '../../gatsby-theme-apollo/client';
import { setActiveModal } from '../../state/ui/uiSlice';

const CampaignsApplication = ({ location }) => {
  const params = queryString.parse(location.search);
  const dispatch = useDispatch();

  const { utm_source, utm_campaign } = params;

  const query =
    utm_source && utm_campaign
      ? { utmSource: { like: utm_source }, utmCampaign: { like: utm_campaign } }
      : utm_source
      ? { utmSource: { like: utm_source } }
      : utm_campaign
      ? { utmCampaign: { like: utm_campaign } }
      : {};

  const noParams = !utm_source && !utm_campaign;

  useQuery(CAMPAIGN_BY_QUERY, {
    client: storyblokClient,
    variables: {
      query,
    },
    onCompleted: (d) => {
      const directMatch = head(get('items', get('matches', d)));
      const wildcardMatch = noParams
        ? undefined
        : find((x) => {
            return (
              (params.utm_source || '').indexOf(
                get('content.utmSource', x).split('...')[0]
              ) > -1
            );
          }, get('items', get('wildcards', d)));

      const globalMatch = d?.matches?.items?.find((x) => x.content?.global);
      const story = noParams ? globalMatch : directMatch || wildcardMatch;

      const hasClosed = story.content.rememberClose
        ? localStorage.getItem(story.full_slug) === 'closed'
        : false;
      if (story && !hasClosed) {
        dispatch(
          setActiveModal({
            type: 'component',
            value: 'campaign',
            meta: {
              story,
              utmSource: params.utm_source,
              withCloseMemory: story.full_slug,
            },
          })
        );
      }
    },
  });

  return null;
};

CampaignsApplication.propTypes = {
  location: PropTypes.object,
};

export default memo(CampaignsApplication);
