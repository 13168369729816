import { useMutation } from '@apollo/client';
import { shopifyClient } from '../../gatsby-theme-apollo/client';
import { useSelector, useDispatch } from 'react-redux';
import gql from 'graphql-tag';

import { get } from '../../lib/nodash';
import checkLimits from '../../lib/cart/checkLimits';
import {
  setErrors as reduxSetErrors,
  setAdjusting as reduxSetAdjusting,
} from '../../state/cart/cartSlice';

import { CART_LINE_ITEMS_UPDATE } from '../../queries/cartQueries';

function useUpdateLineItems() {
  const dispatch = useDispatch();
  const cartId = useSelector((state) => state.cart.cartId);
  const [updateLineItems, { loading }] = useMutation(CART_LINE_ITEMS_UPDATE, {
    client: shopifyClient,
  });

  const adjustLineItemQuantity = async (
    lineItemId,
    variantId,
    quantity,
    isAbsoluteQty = false
  ) => {
    // TODO: debounce and collect events and batch them into one call
    // to allow for faster perceived qty adjustment
    dispatch(reduxSetAdjusting(lineItemId));
    try {
      const { toChange, errors } = await checkLimits(
        [{ variantId, quantity, id: lineItemId }],
        cartId,
        isAbsoluteQty
      );
      if (errors.length > 0) {
        dispatch(reduxSetErrors(errors));
      }

      await updateLineItems({
        variables: { lines: toChange, cartId },
        update: (cache, { data }) => {
          const cart = get('cartLinesUpdate.cart', data);

          cache.writeFragment({
            id: cache.identify(cart),
            fragment: gql`
              fragment CartLineItemsUpdateFragment on Cart {
                lines
                cost {
                  subtotalAmount {
                    amount
                  }
                  totalAmount {
                    amount
                  }
                }
              }
            `,
            data: cart,
          });
        },
      });
      dispatch(reduxSetAdjusting(null));
    } catch (e) {
      dispatch({ type: 'SET_ERROR', payload: e });
    }
  };

  return { updateLineItems, loading, adjustLineItemQuantity };
}

export default useUpdateLineItems;
