import { gql, useMutation } from '@apollo/client';
import { useSelector, useDispatch } from 'react-redux';
import { map, get, head, reject, isNil, find } from '../../lib/nodash';

import { CART_LINE_ITEMS_ADD } from '../../queries/cartQueries';
import { shopifyClient } from '../../gatsby-theme-apollo/client';
import checkLimits from '../../lib/cart/checkLimits';
import extractGid from '../../lib/extractGid';
import {
  setErrors,
  setOpen,
  addToSamplesArrangement,
  clearCart,
  setCartId,
} from '../../state/cart/cartSlice';
import { setActiveModal } from '../../state/ui/uiSlice';
import { track } from '../../lib/analytics';
import { PRODUCT_ADDED } from '../../lib/analytics/segmentActions';
import { CREATE_CART } from '../../queries/cartQueries';
import eventBus from '../../lib/EventBus';
import deNodify from '../../lib/deNodify';
import findImage from '../../lib/findImage';

function useAddToCart() {
  const cartId = useSelector((state) => state.cart.cartId);
  const dispatch = useDispatch();
  const [createCart] = useMutation(CREATE_CART, {
    variables: { input: {} },
    client: shopifyClient,
    onCompleted: (d) => {
      dispatch(setCartId(get('cartCreate.cart.id', d)));
    },
  });

  const [addLineItems, { loading, data }] = useMutation(CART_LINE_ITEMS_ADD, {
    client: shopifyClient,
    update: (cache, { data }) => {
      const cart = get('cartLinesAdd.cart', data);
      cache.writeFragment({
        id: cache.identify(cart),
        fragment: gql`
          fragment CartLineItemsUpdateFragment on Cart {
            lines
            cost {
              subtotalAmount {
                amount
              }
              totalAmount {
                amount
              }
            }
          }
        `,
        data: cart,
      });
    },
    onError: async (e) => {
      if (String(e).indexOf('Unable to access Cart') > -1);
      {
        dispatch(clearCart());
        await createCart();
      }
      dispatch(
        setErrors([
          {
            title: 'Our bad',
            body: 'There was an issue when adding to your cart. Please try again',
          },
        ])
      );
    },
  });

  const addToCart = async (lineItems, options = {}) => {
    const {
      toChange,
      errors,
      samples = [],
      variantData,
    } = await checkLimits(lineItems, cartId, false, {
      inventoryPolicies: options?.inventoryPolicies,
    });

    const isSampleAddition = lineItems.length === 1 && samples.length > 0;
    const apiResponse = await addLineItems({
      variables: {
        lines: map(
          (x) => {
            const { customAttributes = {} } = x;
            return {
              quantity: x.quantity,
              merchandiseId: x.merchandiseId,
              attributes: Object.keys(customAttributes).map((key) => ({
                key,
                value: customAttributes[key],
              })),
            };
          },
          reject((y) => {
            return isNil(y.quantity);
          }, toChange)
        ),
        cartId: cartId,
      },
    });

    const checkoutUrl = get('data.cartLinesAdd.cart.checkoutUrl', apiResponse);

    lineItems.forEach((item) => {
      const variant = find((z) => {
        return z.id === item.variantId;
      }, variantData);
      const images = deNodify(variant.product.images);

      const heroImage = findImage(images, '_hero');
      const collectionImage = findImage(images, '_grid');
      const cartImage = findImage(images, 'cart');
      const productImages = images;

      track(PRODUCT_ADDED, {
        cart_id: cartId,
        product_id: extractGid(get('product.id', variant)),
        product_name: get('product.title', variant),
        image_url: collectionImage,
        hero_image: heroImage,
        cart_image: cartImage,
        product_images: productImages,
        sku: get('sku', variant),
        name: get('product.title', variant),
        variant: get('title', variant),
        variant_id: get('id', variant),
        price: parseFloat(get('priceV2.amount', variant)),
        quantity: item.quantity,
        checkout_url: checkoutUrl,
      });
    });

    eventBus.dispatch('cart:add', lineItems);

    if (errors.length > 0) {
      const oos = errors.find((x) => x.type === 'out-of-stock');
      if (oos) {
        return dispatch(
          setActiveModal({
            type: 'component',
            value: 'product-restock-notification',
            meta: oos.meta,
          })
        );
      }
      return dispatch(setErrors(errors));
    }
    if (isSampleAddition) {
      dispatch(addToSamplesArrangement(get('id', head(samples))));
    }
    dispatch(setOpen(true));
  };

  return { addToCart, loading, data };
}

export default useAddToCart;
